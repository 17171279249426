import { sendNotificationViaTemplate } from './api';

export const notificationTemplateIds = {
  AGREEMENT_SIGNED: '403269ef-d3e4-4299-bcf7-2c03270bb60e',
  DISPUTE_REFUND_INTIATED: '15b21baa-dfe7-418f-9ecb-4942241f9589',
  DROPOFF_NOTES_ADDED: '2477d119-9c0e-4c95-972a-9675f62f8159',
  KART_DELIVERED: '4237a3a7-0b54-4951-951b-9eb732f0059f',
  KART_RETURNED: '079bb44f-fd34-4450-9bac-6591a122eecb',
  ORDER_ACCEPTED: '6e7fee15-098e-4935-b3b4-d42ce78027bc',
  ORDER_DECLINE: '240f9279-52f8-427f-a423-9b413950956d',
  ORDER_DELIVERED_COMPLETED: '2c42c5be-20a3-4be8-bedd-77288944a14e',
  ORDER_DELIVERED_DISPUTE_REFUND_DEPOSITED: '1e7ee6aa-660e-466e-a8d7-b17e5e243b55',
  ORDER_DELIVERED_DISPUTE_REJECTED: 'c9a4c8eb-cc87-4267-a4fd-e67b30dd0008',
  ORDER_DISPUTED: '3c8dc830-5c50-4dd1-9415-2af3f298da96',
  REVIEW_RENTER: '5d47cc29-e448-4440-ab61-fa3e19bc49e3',
  REVIEW_RENTER_2: '3975021f-51ee-4451-b36a-230839177f0c',
  REVIEW_HOST: '754ba30a-b3ea-40fe-ab02-072cb729c0c9',
  REVIEW_HOST_2: '0e0476da-4bef-4e5b-892d-65a341d9b69d',
  REMINDER_AGREEMENT_RENTER: 'd366458a-52c3-4a81-9917-18d6f7976c77',
  REMINDER_PICKUP_DROPOFF: 'eaffb175-186d-4966-a3d4-1c4b840b141e',
  BOOKING_REQUESTED: '8d8928f6-3133-42ba-9b3f-1c3d1311f799',
  CUSTOMER_CANCELED: '05303c92-052f-445a-a0e4-934747e05e6e',
  KART_PICKED: '0d952d56-7178-4da8-9bf2-2edbc4b95a3b',
  ADDITIONAL_FEES_REQUEST: 'd8a3a9aa-8e8a-42d2-a8dd-fb2a8ac2a9fc',
  KART_PICKED_WITH_DISCREPANCY: '21049047-ed96-4371-8b49-59c096200da5',
  SECURITY_DEPOSITED: 'b83a8050-40c8-4508-b78c-3f5888d1832f',
  AGREEMENT_SIGNED_HOST: '7934bed8-f042-4912-a570-d27ebe5d0c4c',
  AGREEMENT_SIGNED_RENTER: '416b2544-f1cf-4bd6-8cc2-b6ffabc03b74',
  CHAT_MESSAGE: 'c56504c5-a296-4398-a636-1f3432dfe30e',
  ADDITIONAL_FEES_ACCEPT: '31502666-9406-4a1a-b83c-fef8ce88b6ba',
  ADDITIONAL_FEES_DECLINE: '45b1c076-d10e-4e88-9b09-5bc1fd3ef856',
};

export const sendPushNotification = params => {
  const { data, receiverIds, template_id, custom_data } = params;

  const body = {
    data,
    include_external_user_ids: receiverIds,
    template_id,
    custom_data,
  };

  sendNotificationViaTemplate(body);
};
