import React, { useEffect } from 'react';

// Import config and utils
import { useIntl } from '../../util/reactIntl';
import {
  SCHEMA_TYPE_ENUM,
  SCHEMA_TYPE_MULTI_ENUM,
  SCHEMA_TYPE_TEXT,
  SCHEMA_TYPE_LONG,
  SCHEMA_TYPE_BOOLEAN,
  SCHEMA_TYPE_SINGLE_TEXT,
} from '../../util/types';
import { required, nonEmptyArray, validateInteger } from '../../util/validators';
import * as validators from '../../util/validators';
// Import shared components
import {
  FieldCheckboxGroup,
  FieldSelect,
  FieldTextInput,
  FieldBoolean,
  FieldRadioButton,
} from '../../components';
// Import modules from this directory
import css from './CustomExtendedDataField.module.css';

const createFilterOptions = options => options.map(o => ({ key: `${o.option}`, label: o.label }));

const getLabel = fieldConfig => fieldConfig?.saveConfig?.label || fieldConfig?.label;

const CustomFieldEnum = props => {
  const { name, fieldConfig, values, defaultRequiredMessage, formId, form, intl } = props;
  const { enumOptions = [], saveConfig } = fieldConfig || {};
  const { placeholderMessage, isRequired, requiredMessage } = saveConfig || {};

  const validateMaybe = isRequired
    ? { validate: required(requiredMessage || defaultRequiredMessage) }
    : {};
  const placeholder =
    placeholderMessage ||
    intl.formatMessage({ id: 'CustomExtendedDataField.placeholderSingleSelect' });

  const filterOptions =
    name === 'pub_DriversLicenseState'
      ? createFilterOptions(enumOptions.filter(item => item.option === 'Florida'))
      : createFilterOptions(enumOptions);

  const label = getLabel(fieldConfig);
  const renderField = [
    'pub_advance_notice',
    'pub_min_trip_duration',
    'pub_max_trip_duration',
    'pub_transport_outside_community',
    'pub_is_registered_lsv',
    'pub_kart_insured',
    'pub_rain_enclosure',
    'pub_outdoor_kart_cover',
  ].includes(name);

  const { pub_seats } = values || {};
  return filterOptions ? (
    renderField ? (
      <div className={css.radioField}>
        <span className={css.radioplaceholder}>{label}</span>

        {filterOptions &&
          filterOptions.map((optionConfig, index) => {
            return (
              <div key={index}>
                <FieldRadioButton
                  id={`${fieldConfig.key}-${index}`}
                  key={`${fieldConfig.key}-${index}`}
                  name={`${fieldConfig.key}`}
                  value={optionConfig.key}
                  label={optionConfig.label}
                />
              </div>
            );
          })}
      </div>
    ) : (
      <>
        {pub_seats == 2 && name == 'pub_seat_position' ? null : name == 'pub_seat_position' ? (
          pub_seats ? (
            <FieldSelect
              className={css.customField}
              name={name}
              id={formId ? `${formId}.${name}` : name}
              label={label}
              {...validateMaybe}
            >
              <option disabled value="">
                {placeholder}
              </option>
              {filterOptions.map(optionConfig => {
                const { key } = optionConfig;
                return (
                  <option key={key} value={key}>
                    {optionConfig.label}
                  </option>
                );
              })}
            </FieldSelect>
          ) : null
        ) : (
          <FieldSelect
            className={css.customField}
            name={name}
            id={formId ? `${formId}.${name}` : name}
            label={label}
            {...validateMaybe}
          >
            <option disabled value="">
              {placeholder}
            </option>
            {filterOptions.map(optionConfig => {
              const { key } = optionConfig;
              return (
                <option key={key} value={key}>
                  {optionConfig.label}
                </option>
              );
            })}
          </FieldSelect>
        )}
      </>
    )
  ) : null;
};

const CustomFieldMultiEnum = props => {
  const { name, fieldConfig, defaultRequiredMessage, formId } = props;
  const { enumOptions = [], saveConfig } = fieldConfig || {};
  const { isRequired, requiredMessage } = saveConfig || {};
  const label = getLabel(fieldConfig);
  const validateMaybe = isRequired
    ? { validate: nonEmptyArray(requiredMessage || defaultRequiredMessage) }
    : {};

  return enumOptions ? (
    <FieldCheckboxGroup
      className={css.customField}
      id={formId ? `${formId}.${name}` : name}
      name={name}
      label={label}
      options={createFilterOptions(enumOptions)}
      {...validateMaybe}
    />
  ) : null;
};
const CustomFieldSingleLineText = props => {
  const { name, fieldConfig, values, defaultRequiredMessage, formId, form, intl } = props;
  const { placeholderMessage, isRequired, requiredMessage } = fieldConfig?.saveConfig || {};
  const { is_registered_lsv, kart_insured } = values || null;
  const label = getLabel(fieldConfig);
  const validateMaybe = isRequired
    ? { validate: required(requiredMessage || defaultRequiredMessage) }
    : {};
  const placeholder =
    placeholderMessage || intl.formatMessage({ id: 'CustomExtendedDataField.placeholderText' });
  const driveLiceneceNumberValidation = validators.regexValidation(
    intl.formatMessage({
      id: 'ProfilePage.driveLiceneceNumber',
    })
  );

  return (
    <>
      {(kart_insured !== 'yes' || null) && name === 'pub_insurance_carrier' ? (
        form.change('pub_insurance_carrier', null)
      ) : (is_registered_lsv !== 'yes' || null) && name === 'pub_license_plate' ? (
        form.change('pub_license_plate', null)
      ) : (
        <FieldTextInput
          className={css.customField}
          id={formId ? `${formId}.${name}` : name}
          name={name}
          type="text"
          label={label}
          placeholder={placeholder}
          {...validateMaybe}
          validate={name === 'pub_license_plate' ? driveLiceneceNumberValidation : null}
        />
      )}
    </>
  );
};
const CustomFieldText = props => {
  const { name, fieldConfig, defaultRequiredMessage, formId, intl, values, form } = props || {};
  const { placeholderMessage, isRequired, requiredMessage } = fieldConfig?.saveConfig || {};
  const label = getLabel(fieldConfig);
  const validateMaybe = isRequired
    ? { validate: required(requiredMessage || defaultRequiredMessage) }
    : {};
  const placeholder =
    placeholderMessage || intl.formatMessage({ id: 'CustomExtendedDataField.placeholderText' });

  const driveLiceneceNumberValidation = validators.regexValidation(
    intl.formatMessage({
      id: 'ProfilePage.driveLiceneceNumber',
    })
  );
  const { pub_type_of_host } = values || {};

  useEffect(() => {
    if (pub_type_of_host !== 'business') {
      form?.change('pub_business_name', null);
    }
  }, [pub_type_of_host]);

  return (
    <>
      {pub_type_of_host === 'business' && name === 'pub_business_name' && (
        <FieldTextInput
          className={css.customField}
          id={formId ? `${formId}.${name}` : name}
          name={name}
          type="text"
          label={label}
          placeholder={placeholder}
          {...validateMaybe}
        />
      )}
      {name !== 'pub_business_name' && (
        <FieldTextInput
          className={css.customField}
          id={formId ? `${formId}.${name}` : name}
          name={name}
          type="text"
          label={label}
          placeholder={placeholder}
          {...validateMaybe}
          // validate={name === 'pub_DriversLicenseNumber' ? driveLiceneceNumberValidation : null}
        />
      )}
    </>
  );
};

const CustomFieldLong = props => {
  const { name, fieldConfig, defaultRequiredMessage, formId, intl, customPlaceholder } = props;
  const { minimum, maximum, saveConfig } = fieldConfig;
  const { placeholderMessage, isRequired, requiredMessage } = saveConfig || {};
  const label = getLabel(fieldConfig);
  const placeholder =
    name === 'pub_top_speed'
      ? intl.formatMessage({ id: 'CustomeFieldLong.placeholderTopSpeed' })
      : name === 'pub_approximate_cart_value'
      ? intl.formatMessage({ id: 'CustomeFieldLong.placeholderCartValue' })
      : name === 'pub_year'
      ? intl.formatMessage({ id: 'CustomeFieldLong.placeholderYear' })
      : placeholderMessage || intl.formatMessage({ id: 'CustomExtendedDataField.placeholderLong' });

  const numberTooSmallMessage = intl.formatMessage(
    { id: 'CustomExtendedDataField.numberTooSmall' },
    { min: minimum }
  );
  const numberTooBigMessage = intl.formatMessage(
    { id: 'CustomExtendedDataField.numberTooBig' },
    { max: maximum }
  );

  // Field with schema type 'long' will always be validated against min & max
  const validate = (value, min, max) => {
    const requiredMsg = requiredMessage || defaultRequiredMessage;
    return isRequired && value == null
      ? requiredMsg
      : validateInteger(value, max, min, numberTooSmallMessage, numberTooBigMessage);
  };

  return (
    <FieldTextInput
      className={css.customField}
      id={formId ? `${formId}.${name}` : name}
      name={name}
      type="number"
      step="1"
      parse={value => {
        const parsed = Number.parseInt(value, 10);
        return Number.isNaN(parsed) ? null : parsed;
      }}
      label={label}
      placeholder={placeholder}
      validate={value => validate(value, minimum, maximum)}
    />
  );
};

const CustomFieldBoolean = props => {
  const { name, fieldConfig, defaultRequiredMessage, formId, intl } = props;
  const { placeholderMessage, isRequired, requiredMessage } = fieldConfig?.saveConfig || {};
  const label = getLabel(fieldConfig);
  const validateMaybe = isRequired
    ? { validate: required(requiredMessage || defaultRequiredMessage) }
    : {};
  const placeholder =
    placeholderMessage || intl.formatMessage({ id: 'CustomExtendedDataField.placeholderBoolean' });

  return (
    <FieldBoolean
      className={css.customField}
      id={formId ? `${formId}.${name}` : name}
      name={name}
      label={label}
      placeholder={placeholder}
      {...validateMaybe}
    />
  );
};

/**
 * Return Final Form field for each configuration according to schema type.
 *
 * These custom extended data fields are for generating input fields from configuration defined
 * in marketplace-custom-config.js. Other panels in EditListingWizard might add more extended data
 * fields (e.g. shipping fee), but these are independently customizable.
 *
 * @param {Object} props should contain fieldConfig that defines schemaType, enumOptions?, and
 * saveConfig for the field.
 */
const CustomExtendedDataField = props => {
  const intl = useIntl();
  const { enumOptions = [], schemaType } = props?.fieldConfig || {};
  const renderFieldComponent = (FieldComponent, props) => <FieldComponent {...props} intl={intl} />;

  return schemaType === SCHEMA_TYPE_ENUM && enumOptions
    ? renderFieldComponent(CustomFieldEnum, props)
    : schemaType === SCHEMA_TYPE_MULTI_ENUM && enumOptions
    ? renderFieldComponent(CustomFieldMultiEnum, props)
    : schemaType === SCHEMA_TYPE_TEXT
    ? renderFieldComponent(CustomFieldText, props)
    : schemaType === SCHEMA_TYPE_LONG
    ? renderFieldComponent(CustomFieldLong, props)
    : schemaType === SCHEMA_TYPE_BOOLEAN
    ? renderFieldComponent(CustomFieldBoolean, props)
    : schemaType === SCHEMA_TYPE_SINGLE_TEXT
    ? renderFieldComponent(CustomFieldSingleLineText, props)
    : null;
};

export default CustomExtendedDataField;
