import {
  TX_TRANSITION_ACTOR_CUSTOMER,
  TX_TRANSITION_ACTOR_PROVIDER,
} from '../../transactions/transaction';
import { transitions } from '../../transactions/transactionProcessBooking';
import { sendPushNotification, notificationTemplateIds } from '../../util/oneSignal';

export const transitionNotification = data => {
  const { id, attributes, provider, customer, listing } = data || {};
  const { hostName, renterName } = attributes.protectedData || {};
  const transition = attributes.transitions;
  const lastTransition = transition[transition.length - 1];
  const receiver = lastTransition.by === 'provider' ? customer.id.uuid : provider.id.uuid;
  const txRole =
    lastTransition.by === 'provider' ? TX_TRANSITION_ACTOR_CUSTOMER : TX_TRANSITION_ACTOR_PROVIDER;

  let template_id;

  switch (lastTransition.transition) {
    case transitions.ACCEPT:
      template_id = notificationTemplateIds.ORDER_ACCEPTED;
      break;
    case transitions.DECLINE:
    case transitions.PROVIDER_CANCEL_AFTER_ACCEPTANCE:
    case transitions.PROVIDER_CANCEL_AFTER_SECURITY_DEPOSIT:
    case transitions.PROVIDER_CANCEL_AFTER_AGREEMENT_SIGNED:
    case transitions.PROVIDER_CANCEL_AFTER_NOTES_ADDED:
      template_id = notificationTemplateIds.ORDER_DECLINE;
      break;
    case transitions.ADD_PICKUP_DROPOFF_NOTES:
      template_id = notificationTemplateIds.DROPOFF_NOTES_ADDED;
      break;
    case transitions.CUSTOMER_CANCEL:
    case transitions.CUSTOMER_CANCEL_AFTER_SECURITY_DEPOSIT:
    case transitions.CUSTOMER_CANCEL_AFTER_AGREEMENT_SIGN_INITIATION:
    case transitions.CUSTOMER_CANCEL_AFTER_AGREEMENT_SIGNED:
    case transitions.CUSTOMER_CANCEL_AFTER_NOTES_ADDED:
      template_id = notificationTemplateIds.CUSTOMER_CANCELED;
      break;
    case transitions.KART_RECEIVED_FROM_PROVIDER:
      template_id = notificationTemplateIds.KART_PICKED;
      break;
    case transitions.KART_RECEIVED_FROM_PROVIDER_WITH_DISCREPANCIES:
      template_id = notificationTemplateIds.KART_PICKED_WITH_DISCREPANCY;
      break;
    case transitions.KART_RETURNED_TO_PROVIDER:
      template_id = notificationTemplateIds.KART_RETURNED;
      break;
    case transitions.COMPLETE:
    case transitions.ADDITIONAL_FEES_DEPOSIT:
    case transitions.DISPUTE_REFUND_DEPOSIT:
    case transitions.DISPUTE_REJECTED:
      template_id = notificationTemplateIds.ORDER_DELIVERED_COMPLETED;
      break;
    case transitions.ADDITIONAL_FEES_REQUEST:
      template_id = notificationTemplateIds.ADDITIONAL_FEES_REQUEST;
      break;
    case transitions.SECURITY_DEPOSIT_PAYMENT:
    case transitions.DEPOSIT_SECURITY_AMOUNT_AFTER_REMINDER:
      template_id = notificationTemplateIds.SECURITY_DEPOSITED;
      break;
    case transitions.REVIEW_1_BY_PROVIDER:
      template_id = notificationTemplateIds.REVIEW_HOST;
      break;
    case transitions.REVIEW_1_BY_CUSTOMER:
      template_id = notificationTemplateIds.REVIEW_RENTER;
      break;
    case transitions.REVIEW_2_BY_PROVIDER:
      template_id = notificationTemplateIds.REVIEW_HOST_2;
      break;
    case transitions.REVIEW_2_BY_CUSTOMER:
      template_id = notificationTemplateIds.REVIEW_RENTER_2;
      break;
    default:
      console.log('no matching transition');
  }

  const notificationData = {
    data: {
      template_id,
      transactionId: id.uuid,
      transactionRole: txRole,
    },
    receiverIds: [receiver],
    template_id,
    custom_data: {
      sender: lastTransition.by === 'provider' ? hostName : renterName,
      title: listing.attributes.title,
      image_url: listing.images[0].attributes.variants['default'].url,
    },
  };

  if (notificationData.template_id) {
    sendPushNotification(notificationData);
  }
};

export const generateEmailTemplate = (data, txId) => {
  const getSafeValue = (value, defaultValue = 'N/A') => value ?? defaultValue;

  const generateMediaHTML = mediaArray => {
    return (
      mediaArray
        ?.map(media => {
          if (media.type === 'image') {
            return `<a href="${media.url}" target="_blank" rel="noopener noreferrer">
                  <img src="${media.url}" style="width: 200px; height: 200px; border-radius: 4px;" alt="Media content" loading="lazy" />
                </a>`;
          } else if (media.type === 'video') {
            return `<video controls width="200" height="200" loading="lazy" style="object-fit: cover; border-radius: 4px;">
                  <source src="${media.url}" type="video/mp4">
                  Your browser does not support the video. <a href="${media.url}" target="_blank" rel="noopener noreferrer">Watch here</a>
                </video>`;
          }
          return '';
        })
        .join('') || ''
    );
  };

  return `
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
    </head>
    <body>
        <table style="background-color:#FFF;margin:0 auto;padding:24px 12px 0;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" width="100%">
            <tbody>
                <tr>
                    <td>
                        <table align="center" role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%" style="max-width:600px;margin:0 auto">
                            <tr style="width:100%">
                                <td>
                                    <h1 style="color:#484848;font-size:26px;line-height:1.3;font-weight:700">
                                        Transaction ID - ${txId} is marked as a disputed by renter.
                                    </h1>
                 
                                    <h2>Dispute summary as of now:</h2>
                                    <h2>=> Dropoff details by Renter</h2>
                                    <p style="font-size:16px;line-height:1.4;margin:16px 0;color:#484848">
                                        <strong>By Renter:</strong> ${getSafeValue(
                                          data?.notes?.host_name
                                        )}
                                    </p>
                                    <p style="font-size:16px;line-height:1.4;margin:16px 0;color:#484848">
                                        <strong>Charge level:</strong> ${getSafeValue(
                                          data?.dropOffDetails?.chargeLevel
                                        )}
                                    </p>
                                    <p style="font-size:16px;line-height:1.4;margin:16px 0;color:#484848">
                                        <strong>Cart returned in same condition:</strong> ${getSafeValue(
                                          data?.dropOffDetails?.isSame_condition
                                        )}
                                    </p>
                                    <p style="font-size:16px;line-height:1.4;margin:16px 0;color:#484848">
                                        <strong>Drop off location:</strong> ${getSafeValue(
                                          data?.dropOffDetails?.dropOffLocation
                                        )}
                                    </p>
                                    <p style="font-size:16px;line-height:1.4;margin:16px 0;color:#484848">
                                        <strong>Returned accessories:</strong> ${getSafeValue(
                                          data?.dropOffDetails?.returned_accessories
                                        )}
                                    </p>
                                    <p style="font-size:16px;line-height:1.4;margin:16px 0;color:#484848">
                                        <strong>Damage notes:</strong> ${getSafeValue(
                                          data?.dropOffDetails?.damage_notes
                                        )}
                                    </p>
                                    <h3>Media at time of returning Cart</h3>
                                    ${generateMediaHTML(data?.dropOffDetails?.media)}
                                    <h2>=> Additional fees details by Host</h2>
                                    <p style="font-size:16px;line-height:1.4;margin:16px 0;color:#484848">
                                        Additional fees requested: $${getSafeValue(
                                          data?.additionalfeeRequested?.additionalRequestedFee
                                        )}
                                    </p>

                                    <strong>Reasons:</strong>
                                    <ol>
                                        ${data?.additionalfeeRequested?.selectedFees
                                          ?.map(fee => `<li>${fee.label}</li>`)
                                          .join('') || ''}
                                    </ol>

                                    <p style="font-size:16px;line-height:1.4;margin:16px 0;color:#484848">
                                        Comments: ${getSafeValue(
                                          data?.additionalfeeRequested?.comment
                                        )}
                                    </p>
                                    <h3>Media</h3>
                                    ${generateMediaHTML(data?.additionalfeeRequested?.media)}
                                    <h2>=> Additional fees decline by Renter</h2>
                                    <p style="font-size:16px;line-height:1.4;margin:16px 0;color:#484848">
                                        Additional details: ${getSafeValue(
                                          data?.declineAdditionalFee?.additional_details
                                        )}
                                    </p>
                                    <h3>Media</h3>
                                    ${generateMediaHTML(data?.declineAdditionalFee?.media)}
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>
    </body>
    </html>
  `;
};

const CANCELLATION_TRANSITIONS = [
  transitions.PROVIDER_CANCEL_AFTER_ACCEPTANCE,
  transitions.PROVIDER_CANCEL_AFTER_SECURITY_DEPOSIT,
  transitions.CUSTOMER_CANCEL_AFTER_SECURITY_DEPOSIT,
  transitions.PROVIDER_CANCEL_AFTER_AGREEMENT_SIGN_INITIATION,
  transitions.CUSTOMER_CANCEL_AFTER_AGREEMENT_SIGN_INITIATION,
  transitions.PROVIDER_CANCEL_AFTER_SENT_REMINDER_AGREEMENT_SIGN_BY_RENTER,
  transitions.CUSTOMER_CANCEL_AFTER_SENT_REMINDER_AGREEMENT_SIGN_BY_RENTER,
  transitions.PROVIDER_CANCEL_AFTER_SENT_REMINDER_AGREEMENT_SIGN_BY_HOST,
  transitions.CUSTOMER_CANCEL_AFTER_SENT_REMINDER_AGREEMENT_SIGN_BY_HOST,
  transitions.PROVIDER_CANCEL_AFTER_AGREEMENT_SIGNED,
  transitions.CUSTOMER_CANCEL_AFTER_AGREEMENT_SIGNED,
  transitions.CUSTOMER_CANCEL_AFTER_AGREEMENT_SIGNED_BY_RENTER,
  transitions.PROVIDER_CANCEL_AFTER_AGREEMENT_SIGNED_BY_RENTER,
  transitions.PROVIDER_CANCEL_AFTER_SENT_REMINDER_ADD_PICKUP_DROPOFF_NOTES,
  transitions.CUSTOMER_CANCEL_AFTER_SENT_REMINDER_ADD_PICKUP_DROPOFF_NOTES,
  transitions.PROVIDER_CANCEL_AFTER_NOTES_ADDED,
  transitions.CUSTOMER_CANCEL_AFTER_NOTES_ADDED,
];

const createReversalItems = lineItems => {
  return lineItems.map(item => ({
    ...item,
    reversal: true,
    lineTotal: {
      ...item.lineTotal,
      amount: -item.lineTotal.amount,
    },
    ...(item.percentage && {
      percentage: {
        ...item.percentage,
        value: `-${item.percentage.value}`,
      },
    }),
    ...(item.quantity && {
      quantity: {
        ...item.quantity,
        value: `-${item.quantity.value}`,
      },
    }),
  }));
};

export const validateTransactionForCancellation = transaction => {
  const { lineItems, lastTransition, payinTotal, payoutTotal } = transaction?.attributes || {};

  const isCancellationTransition = CANCELLATION_TRANSITIONS.includes(lastTransition);
  if (isCancellationTransition) {
    // Create reversal items and return the updated transaction
    const reversalItems = createReversalItems(lineItems);
    const updatedPayinTotal = {
      ...payinTotal,
      amount: 0,
    };
    const updatedPayoutTotal = {
      ...payoutTotal,
      amount: 0,
    };
    const updatedLineItems = [...lineItems, ...reversalItems];
    return {
      ...transaction,
      attributes: {
        ...transaction.attributes,
        lineItems: updatedLineItems,
        payinTotal: updatedPayinTotal,
        payoutTotal: updatedPayoutTotal,
      },
    };
  }
  return transaction;
};

export const calculateStripeRefund = (total, lineItems) => {
  const payinTotal = total.amount;

  const customerCommission =
    lineItems.find(item => item.code === 'line-item/customer-commission')?.lineTotal.amount || 0;

  const refundAmount = payinTotal - customerCommission;

  return { refundAmount, customerCommission };
};
